import ApiService from './ApiService'

export async function apiGetAllData(params) {
    return ApiService.fetchData({
        url: `post/list?isVideo=${params.isVideo}&categoryId=${params.categoryId}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&keyword=${params.keyword}&topic=${params.topic}`,
        method: 'get'
    })
}

export async function apiGetAllCategory() {
    return ApiService.fetchData({
        url:'post/getListCategories',
        method:'get',
    })
}

export async function apiUploadThumnail(data) {
    return ApiService.fetchData({
        url:'post/upload',
        method:'post',
        data
    })
}

export async function apiCreatePost(data) {
    return ApiService.fetchData({
        url:'post/create',
        method:'post',
        data
    })
}

export async function apiEditPost(data) {
    return ApiService.fetchData({
        url:'post/update',
        method:'post',
        data
    })
}

export async function apiGetPostById(id) {
    return ApiService.fetchData({
        url:`post/getById?id=${id}`,
        method:'get',
    })
}

export async function apiChangeStatus(data) {
    return ApiService.fetchData({
        url:`post/changeStatus`,
        method:'post',
        data
    })
}
