import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  avatar: "",
  userName: "",
  email: "",
  authority: [],
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      (state.avatar = action.payload.avatar),
        (state.userName = action.payload.userName),
        (state.displayName = action.payload.displayName),
        (state.email = action.payload.email),
        (state.authority = ["USER"]);
    },
    userLoggedOut: () => initialState,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
