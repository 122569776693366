import { forwardRef, useMemo, useState } from "react";
import { Loading } from "components/shared";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { apiUploadThumnail } from "services/PostService";
import ImageResize from 'quill-image-resize-module-react';


Quill.register("modules/imageResize", ImageResize);
var quillObj;

const RichTextEditor = forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      setIsLoading(true);
      const file = input.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const range = quillObj.getEditorSelection();
      const res = await apiUploadThumnail(formData);
      const imgUploaded = res.data.data;
      quillObj.getEditor().insertEmbed(range.index, "image", imgUploaded);
      setIsLoading(false);
    };
  };

  var editorModules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],

          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],

          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          ["link", "image", 'video'],

          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],

          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  var editorFormats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  return (
    <div className="rich-text-editor">
      <Loading loading={isLoading} type="cover">
        <ReactQuill
          ref={(el) => {
            quillObj = el;
          }}
          modules={editorModules}
          formats={editorFormats}
          theme="snow"
          enabled={true}
          {...props}
        />
      </Loading>
    </div>
  );
});

export default RichTextEditor;
