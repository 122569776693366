import BaseDashboardService from './BaseDashboardService'
import BaseService from './BaseService'

const ApiService = {
    fetchData(param, isDashboard) {
        if (!isDashboard) {
            return new Promise((resolve, reject) => {
                BaseService(param)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((errors) => {
                        reject(errors)
                    })
            })
        } else {
            return new Promise((resolve, reject) => {
                BaseDashboardService(param)
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((errors) => {
                        reject(errors)
                    })
            })
        }
        
    },
}

export default ApiService
